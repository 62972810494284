
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1488e73779d7682f': `1) Ouvrez le menu de votre navigateur`,
's182ab2d6c997515f': `Ajoutez-le à votre écran d'accueil pour une expérience étendue et un accès facile.`,
's1b9047d53d9f9d22': `2) Appuyez sur Partager dans la barre de navigation`,
's2d90afa5dcca2c0e': `3) Faites défiler vers le bas jusqu'à "Ajouter à l'écran d'accueil"`,
's37a9e8aec5713460': `Plus`,
's4593f304dac8848c': `2) Touchez "Ajouter à l'écran d'accueil"`,
's54cfbb14f46396d4': `1) Ouvrir dans votre navigateur principal`,
's6196153c4b0c1ea0': `Installer`,
's922329d6f6213590': `Ajouter au Dock`,
'sa5ef80b4bb9b39f8': `Moins`,
'sa7551383d1897fcc': `2) Appuyez sur Ajouter au Dock`,
'sba52286c21552a4e': `Installez-le sur votre appareil pour une expérience complète et un accès facile.`,
'sc79fd7641eb9a975': `Masquer les instructions`,
'scdaf4bbff76674c8': `Ajouter à l'écran d'accueil`,
'se740f75b95a51807': `Ajoutez-le à votre Dock pour une expérience étendue et un accès facile.`,
'sfea652f6580ff086': `Ce site possède des fonctionnalités d'application.`,
    };
  